.o-contact {
  & &__wrap {
    > .wpb_column {
      padding: 0 3em;
      @media (--phone) {
        padding: 0;
      }

      @media (--desktop) {
        &:first-child,
        .wpb_single_image {
          text-align: right;
        }
      }

      &:last-child {
        border-left: 1px solid var(--blue);
        @media (--phone) {
          margin-top: 2em;
          border-left: none;
        }
      }
    }
  }
}
