.o-policy {
  > .wpb_column > .vc_column-inner > .wpb_wrapper {
    max-width: 60em;
    margin: 0 auto;
    justify-content: center;
  }

  .wpb_single_image {
    width: calc(100% / 5);
    padding: 0 1em;
    @media (--ipad) {
      width: calc(100% / 3);
      margin-top: 2em;
    }
    @media (--ipad_mini) {
      width: calc(100% / 3);
      margin-top: 2em;
    }
    @media (--phone) {
      width: 50%;
      margin-top: 2em;
    }

    .vc_single_image-img {
      width: auto;
      height: 4em;
    }
  }
}
