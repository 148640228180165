.o-event {
  & &__item {
    --bdrs: 2em;
    @media (--desktop) {
      --bdrs: 4em;
    }

    overflow: hidden;
    position: relative;
    @media (--phone) {
      + .o-event__item {
        margin-top: 1em;
      }
    }

    &:first-child {
      grid-area: span 2 / span 2;
      border-radius: 0 0 0 var(--bdrs);
      @media (--phone) {
        grid-area: none;
        border-radius: 0 var(--bdrs) 0 0;
      }
    }

    &:nth-child(2) {
      border-radius: 0 var(--bdrs) 0 0;
      @media (--phone) {
        border-radius: unset;
      }
    }

    &:last-child {
      @media (--phone) {
        border-radius: 0 0 0 var(--bdrs);
      }
    }
  }

  &&_themes_list &__item {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    width: 100%;
    margin: 0;
    border-radius: 0;

    + .o-event__item {
      margin: 2em 0 0;
      padding: 2em 0 0 !important;
      border-top: 1px solid gray;
    }
  }
}
