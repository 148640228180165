.u-btn {
  &&_themes_around {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 1em;
    transition: background-color .3s;

    &:hover {
      background-color: white;
      color: var(--blue);
    }
  }
}
