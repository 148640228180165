.o-feature {
  & &__content {
    width: 50%;
    padding: 5%;
    @media (--ipad) {
      width: 100%;
      padding: 5% 0;
    }
    @media (--ipad_mini) {
      width: 100%;
      padding: 5% 0;
    }
    @media (--phone) {
      width: 100%;
      padding: 5% 0;
    }
  }

  & &__item:nth-child(2n+1) &__content {
    float: right;
  }

  & &__item:nth-child(2n) &__content {
    float: left;
  }
}
