.o-industry {
  & &__wrap {
    height: 100%;
    padding: 20% 1em;
    @media (--desktop) {
      height: 32em;
      padding: 50% 1em 0;
      border-radius: 20% 30% 0 0 / 7% 15% 0 0;
    }

    > .vc_column-inner > .wpb_wrapper {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      @media (--desktop) {
        transform: skewY(calc( var(--deg) * -1));
        writing-mode: horizontal-tb;
      }
    }
  }

  & &__item:hover &__wrap {
    @media (--desktop) {
      padding: 25% 3em 0;
      border-radius: 30% 10% 0 0 / 20% 6% 0 0;
    }

    > .vc_column-inner > .wpb_wrapper {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  & &__item:nth-last-child(1) &__wrap {
    @media (--ipad) {
      border-radius: 0 0 4em 0;
    }
    @media (--ipad_mini) {
      border-radius: 0 0 4em 0;
    }
    @media (--phone) {
      border-radius: 0 0 4em 0;
    }
  }

  & &__item:nth-last-child(2) &__wrap {
    @media (--ipad) {
      border-radius: 0 0 0 1em;
    }
    @media (--ipad_mini) {
      border-radius: 0 0 0 1em;
    }
    @media (--phone) {
      border-radius: 0 0 0 1em;
    }
  }

  & &__item:nth-child(1) &__wrap {
    background-color: var(--industry__100);
    @media (--ipad) {
      border-radius: 4em 0 0 0;
    }
    @media (--ipad_mini) {
      border-radius: 4em 0 0 0;
    }
    @media (--phone) {
      border-radius: 4em 0 0 0;
    }
  }

  & &__item:nth-child(2) &__wrap {
    background-color: var(--industry__200);
    @media (--ipad) {
      border-radius: 0 1em 0 0;
    }
    @media (--ipad_mini) {
      border-radius: 0 1em 0 0;
    }
    @media (--phone) {
      border-radius: 0 1em 0 0;
    }
  }

  & &__item:nth-child(3) &__wrap {
    background-color: var(--industry__300);
  }

  & &__item:nth-child(4) &__wrap {
    background-color: var(--industry__400);
  }

  & &__item:nth-child(5) &__wrap {
    background-color: var(--industry__500);
  }

  & &__item:nth-child(6) &__wrap {
    background-color: var(--industry__600);
  }

  @media (--desktop) {
    & &__item:nth-child(1):hover &__wrap {
      background: linear-gradient(color( var(--industry__100) a(.9) )), url(../images/bg_industry.jpg) center/cover no-repeat;
    }

    & &__item:nth-child(2):hover &__wrap {
      background: linear-gradient(color( var(--industry__200) a(.9) )), url(../images/bg_industry.jpg) center/cover no-repeat;
    }

    & &__item:nth-child(3):hover &__wrap {
      background: linear-gradient(color( var(--industry__300) a(.9) )), url(../images/bg_industry.jpg) center/cover no-repeat;
    }

    & &__item:nth-child(4):hover &__wrap {
      background: linear-gradient(color( var(--industry__400) a(.9) )), url(../images/bg_industry.jpg) center/cover no-repeat;
    }

    & &__item:nth-child(5):hover &__wrap {
      background: linear-gradient(color( var(--industry__500) a(.9) )), url(../images/bg_industry.jpg) center/cover no-repeat;
    }

    & &__item:nth-child(6):hover &__wrap {
      background: linear-gradient(color( var(--industry__600) a(.9) )), url(../images/bg_industry.jpg) center/cover no-repeat;
    }
  }
}
