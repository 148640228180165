.o-milestone {
  & &__title {
    position: relative;
    z-index: var(--zIndex__bottom);
    height: 2em;
    padding: .3em 0;
    border-top: 1px solid var(--blue);
    border-radius: 2em 2em 2em 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 30%;
      border-style: solid;
      border-color: var(--blue);
      border-width: 0 1px 1px 0;
      border-radius: 2em;
      clip-path: polygon(50% 0, 110% 0, 110% 110%, 50% 110%);
    }
  }
}
