.o-event {
  &&_themes_list &__time {
    width: 20%;
    font: 700 2.4rem/1 "Proxima Nova", sans-serif;
    text-align: right;
    color: var(--blue);
    @media (--phone) {
      font-size: 1.4rem;
    }
  }
}
