.o-feature {
  & &__item {
    margin: 0;

    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      overflow: hidden;
    }

    .wpb_single_image {
      --bdrs: 4em;
      overflow: hidden;
      width: 50%;
      @media (--ipad) {
        width: 100%;
      }
      @media (--ipad_mini) {
        width: 100%;
      }
      @media (--phone) {
        width: 100%;
      }
    }

    &:nth-child(2n+1) {
      .wpb_single_image {
        overflow: hidden;
        float: left;
        border-radius: var(--bdrs) 0 0 0;
      }
    }

    &:nth-child(2n) {
      .wpb_single_image {
        float: right;
        border-radius: 0 0 var(--bdrs) 0;
      }
    }
  }
}
