.mainnav {
  .menu {
    .menu-item {
      text-transform: capitalize;
    }
  }
}

#mainnav {
  .menu {
    .menu-item {
      padding: 0 1.8em;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      &.current_page_item,
      &.current_menu_item,
      &.current-menu-ancestor,
      &.current-menu-parent,
      &.current_page_parent,
      &.current_page_ancestor {
        > a {
          font-weight: 600;
          color: var(--blue);
        }
      }
    }

    .sub-menu {
      left: 50%;
      transform: translateX(-50%);

      &::after {
        display: none;
      }

      > .menu-item {
        padding: 2px 0;

        > a {
          padding: 3px 0;
          border-top: none;
          text-align: center;

          &:hover {
            background-color: white;
            font-weight: 700;
            color: var(--blue);
          }
        }
      }
    }
  }
}
