.c-gallery {
  .wpb_image_grid_ul {
    display: grid;
    grid: repeat(2, 1fr) / repeat(3, 1fr);
    grid-gap: 3px;
    height: auto !important;
    @media (--phone) {
      display: block;
    }

    .isotope-item {
      --bdrs: 2em;
      @media (--desktop) {
        --bdrs: 4em;
      }

      overflow: hidden;
      position: relative !important;
      left: auto !important;
      top: auto !important;
      @media (--phone) {
        + .isotope-item {
          margin-top: 1em;
        }
      }

      &:nth-child(4n+1) {
        grid-area: span 2 / span 2;
        @media (--phone) {
          grid-area: none;
        }
      }

      &:nth-child(5n+1) {
        border-radius: 0 0 0 var(--bdrs);
      }

      &:nth-child(3n+2) {
        border-radius: 0 var(--bdrs) 0 0;
        @media (--phone) {
          border-radius: unset;
        }
      }

      &:first-child {
        @media (--phone) {
          border-radius: 0 var(--bdrs) 0 0;
        }
      }
      &:last-child {
        @media (--phone) {
          border-radius: 0 0 0 var(--bdrs);
        }
      }

      > a {
        display: block;
        height: 100%;
      }

      .attachment-full {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .6s;
      }

      &:hover {
        .attachment-full {
          transform: scale(1.1, 1.1);
        }
      }
    }
  }
}
