.o-career {
  & &__item {
    overflow: hidden;
    width: 50%;
    padding: 3px;
    margin: 0;
    @media (--ipad_mini) {
      width: 100%;
    }
    @media (--phone) {
      width: 100%;
    }

    &:first-child > .wpb_column {
      border-radius: 5em 0 0 0;
    }

    &:last-child > .wpb_column {
      border-radius: 0 0 5em 0;
    }
    
    > .wpb_column {
      padding: 15% 10% 6%;
      background-color: var(--blue);
    }
  }
}
