.o-event {
  & &__image {
    display: block;
    height: 100%;

    .wp-post-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & &__item:hover &__image {
    transform: scale(1.1, 1.1);
    transition: transform .6s;
  }
}
