.o-event {
  & &__cover {
    width: 100%;
    height: 10em;
    margin: 2em 0 0;
    border-radius: 2em 0;
    object-fit: cover;
    cursor: pointer;
  }
}
