.o-industry {
  & &__item {
    @media (--desktop) {
      --deg: 7deg;
    }

    width: 50%;
    padding: 0 .5em;
    margin: 1em 0 0 0;
    transition: flex .8s;
    @media (--desktop) {
      flex: 1 1 300px;
      margin: 0;
      padding: 0 1px;
      transform: translateY(20%) skewY(var(--deg));
    }

    &:hover {
      @media (--desktop) {
        flex: 2 1 600px;
      }
    }
  }
}
