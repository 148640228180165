.o-core {
  .wpb_single_image {
    width: calc(100% / 4);
    padding: 0 1em;
    margin-top: 2em;
    @media (--ipad) {
      width: calc(100% / 2);
    }
    @media (--ipad_mini) {
      width: calc(100% / 2);
    }
    @media (--phone) {
      width: 50%;
    }

    @media (--desktop) {
      .wpb_singleimage_heading {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .vc_single_image-img {
      width: auto;
      height: 4em;
    }
  }
}
