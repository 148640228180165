@for $i from 1 to 10 {
  .u-mt--$(i) {
    margin-top: $(i)em;
  } 

  @media (--desktop) {
    .u-mt--$(i)\@lg {
      margin-top: $(i)em;
    } 
  }

  .u-mt--$(i)\@md {
    @media (--ipad) {
      margin-top: $(i)em;
    } 
    @media (--ipad_mini) {
      margin-top: $(i)em;
    } 
  }

  @media (--phone) {
    .u-mt--$(i)\@xs {
      margin-top: $(i)em;
    } 
  }
}
