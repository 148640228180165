.header-clone {
  display: none;
}
.site-header {
  position: fixed;
  @media (--desktop) {
    padding-bottom: 8%;
    background: linear-gradient(white, transparent);
  }
  @media (--phone) {
    padding: 10px 0;
  }

  &.fixed {
    position: fixed;
  }
}
