.u-flex {
  > .wpb_column > .vc_column-inner > .wpb_wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
@media (--desktop) {
  .u-flex\@lg {
    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}
.u-flex\@md {
  @media (--ipad) {
    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
  @media (--ipad_mini) {
    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}
@media (--phone) {
  .u-flex\@xs {
    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}
