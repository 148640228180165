.o-event {
  &&_themes_list {
    overflow: hidden;
    display: block;
    grid: none;
    width: 100% !important;
    height: auto !important;

    .entry-page p,
    .entry-post p {
      margin-bottom: 0;
    }
  }
}
