body.single.single-post {
  .entry-thumb,
  .comment-respond,
  .comments-area,
  .byline {
    display: none;
  }

  .post-wrap {
    padding: 3em 0;
  }

  .navigation {
    margin-bottom: 0;
  }

  .entry-header {
    padding: 0 1em;
    text-align: center;

    .title-post {
      font-size: 1.5rem;
      @media (--phone) {
        font-size: 1.2rem;
      }
    }

    .single-meta {
      display: block;
      text-decoration: none;
    }
  }

  .entry-content {
    margin-top: 4em;
    font: 400 .9rem/1.4 Muli, sans-serif;
  }
}
