.o-event {
  & &__content {
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10%;
    background-color: var(--blue_light);
    color: white;
    opacity: 0;
    transition: opacity .6s;
  }

  & &__item:hover &__content {
    opacity: 1;
  }
}
