.o-milestone {
  & &__item {
    width: calc( 100% / 3 );
    margin-top: 3em;
    padding: 0 2.5em;
    @media (--ipad) {
      width: calc( 100% / 2 );
    }
    @media (--ipad_mini) {
      width: calc( 100% / 2 );
    }
    @media (--phone) {
      width: 100%;
    }

    > .wpb_wrapper {
      height: 100%;
    }
  }
}
