.u-icon {
  width: 1.5em;
  height: 1.5em;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

@each $i in plywood, osb, plantation, biomass, flooring, property {
  .u-icon.u-icon--$(i) {
    background-image: url(../images/icon_industry-$(i)@3x.png);
  }
}
