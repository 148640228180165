.o-industry {
  & &__content {
    @media (--desktop) {
      writing-mode: tb-rl;
      writing-mode: vertical-rl;
    }
  }

  & &__item:hover &__content {
    @media (--desktop) {
      writing-mode: lr-tb;
      writing-mode: horizontal-lr;
    }
  }
}
