.footer-widgets {
  text-align: center;
  padding: 2em 0 0;
  background-color: var(--blue_light);
  @media (--desktop) {
    text-align: left;
  }

  address {
    margin-bottom: 0;
  }

  .widget-title {
    margin: 0;
    padding: 0 0 .4em;
    font-weight: 700;
  }

  > .container {
    border-bottom: 1px solid white;
    @media (--phone) {
      border-bottom-color: color(white a(.4));
    }
  }

  &.widget-area .widget {
    margin-bottom: 0;
    padding-top: 1em;

    &:last-child {
      margin-bottom: 1em;
    }
  }

  @media (--desktop) {
    #custom_html-2 {
      padding-left: 10%;
    }
    #media_image-2 {
      margin-top: 20%;
    }
  }

  .widget {
    .menu {
      @media (--desktop) {
        display: flex;
        flex-flow: column wrap;
        height: 7em;
        padding-left: 20%;
      }

      &-item {
        display: inline-block;
        padding-left: 1em;
        text-transform: capitalize;
        @media (--desktop) {
          display: block;

          &:not(:nth-child(3n+1)) {
            position: relative;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 2.2em;
              height: 1px;
              background-color: color( white a(.5) );
            }
          }
        }

        &.current_menu_item,
        &.current_page_item {
          > a {
            font-weight: 600;
          }
        }
      }
    }

    .sub-menu {
      display: none;
    }
  }
}
