.u-overflow {
  &--hidden {
    overflow: hidden;
  }
  
  @media (--desktop) {
    &--hidden\@lg {
      overflow: hidden;
    }
  }

  &--hidden\@md {
    @media (--ipad) {
      overflow: hidden;
    }
    @media (--ipad_mini) {
      overflow: hidden;
    }
  }

  @media (--phone) {
    &--hidden\@xs {
      overflow: hidden;
    }
  }
}
