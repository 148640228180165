.o-post-categories {
  & &__btn {
    display: none;
    border-radius: .8em;
  }

  &__item:hover &__btn {
    display: inline-block;
    margin-top: 1em;
    align-self: flex-start;
  }
}
