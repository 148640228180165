.u-head {
  --wh: 20px;

  position: relative;
  padding: 0 0 1em 0;
  background: url(../images/icon_head@3x.png) 50% 100%/var(--wh) var(--wh) no-repeat;
  text-align: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: calc(var(--wh) / 2);
    width: 1em;
    height: 1px;
    background-color: var(--blue);
  }

  &::before {
    transform: translateX(calc( -100% - var(--wh) ));
  }

  &::after {
    transform: translateX(calc( var(--wh) ));
  }
}
