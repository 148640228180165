.wpcf7 {
  margin-top: 1px;

  input {
    &[type="text"],
    &[type="email"],
    &[type="tel"] {
      width: calc(( 100% - 2em ) / 3);
      margin-top: 1em;
      border-color: var(--black);
      border-radius: 2em 2em 0 0;
      color: var(--black);
      font: 400 1rem/1.2 Muli, sans-serif;
      @media (--phone) {
        width: 100%;
      }

      &::placeholder {
        color: var(--black);
        text-transform: capitalize;
        opacity: 1;
      }
      &::-ms-placeholder {
        color: var(--black);
        text-transform: capitalize;
      }
      &:-ms-placeholder {
        color: var(--black);
        text-transform: capitalize;
      }
    }

    &[name="phone"],
    &[name="your-email"] {
      margin-left: 1em;
      @media (--phone) {
        margin-left: 0;
      }
    }

    &[name="your-message"] {
      width: 100%;
    }

    &[type="submit"] {
      padding: 0 3em;
      border-radius: 1em 2em 0 0;
      border: none;
      background-color: var(--blue);
      text-transform: uppercase;
      color: white;

      &:hover {
        background-color: var(--green);
      }
    }
  }

  .o-join {
    input {
      &[type="email"],
      &[type="text"],
      &[type="tel"] {
        @media (--desktop) {
          width: calc(50% - .5em);
        }
      }

      &[name="your-email"] {
        margin-left: 0;
      }

      &[name="position"] {
        @media (--desktop) {
          margin-left: 1em;
        }
      }
    }
  }
}
