.o-post-categories {
  & &__head {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2em;
    transition: background-color .7s;
  }

  &__item:hover &__head {
    justify-content: center;
    background-color: color( var(--blue) a(.7) );
  }
}
