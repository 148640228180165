.o-milestone {
  & &__content {
    position: relative;
    height: calc(100% - 2em);
    padding: 1.6em;
    margin-top: -1px;
    border: 1px solid var(--blue);
    border-radius: 2em 0 3em 3em;

    &::after,
    &::before {
      content: '';
      position: absolute;
    }

    &::before {
      top: -2px;
      right: -2px;
      width: 2em;
      height: 2.5em;
      background-color: white;
    }

    &::after {
      top: -1.4em;
      right: calc(-3em + 3px);
      width: 3em;
      height: 4em;
      background: url(../images/bg_milestone_arrow.png) 100% 100%/contain no-repeat;
      filter: brightness(.7);
    }
  }
}
