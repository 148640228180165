.wpb_single_image {
  display: flex;
  flex-flow: column nowrap;

  .wpb_heading {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    order: 2;
    height: 2.5em;
    margin-top: 1em !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    text-transform: capitalize;
    font: 500 1rem/1.2 Muli, sans-serif !important;
  }
}
